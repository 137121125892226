import { TZDate } from '@date-fns/tz';
import charcoal_serika from './img/charcoal_serika.jpeg'
import charcoal_bottom from './img/charcoal_bottom.jpeg'
import charcoal_serika_side from './img/charcoal_serika_side.jpeg'
import sand_pwork from './img/sand_pwork.jpeg'
import sand_pwork_side from './img/sand_pwork_side.jpeg'
import sand_muted_logo from './img/sand_muted_logo.jpg'
import sand_muted_angle from './img/sand_muted_angle.jpg'
import sand_muted_back from './img/sand_muted_back.jpg'

const VanagonR4Page = [
  {
    type: 'header',
    value: 'V4N4G0N R4 Hall Effect MX Hybrid $375'
  },
  {
    type: 'sub-header',
    value: 'Group Buy Open from January 1, 2025 to February 1, 2025'
  },
  // {
  //   type: 'updates',
  //   updates: [
  //     // {date: '3-25-2022', content: 'I have completed bundling all kits. I have shipped about 2/3 of the orders and continue to push more out each day. Extra parts will get listed here after GB shipping concludes'},
  //     // {date: '2-16-2022', content: 'Anodizer claims that half of the cases should be shipped by this coming Friday'},
  //     // {date: '10-28-2021', content: 'I received word that the cases should be finished up within the next couple weeks. If I get tracking or anything more specific I will post another update here.'},
  //     // {date: '10-12-2021', content: 'All parts for the kits are ready except for the cases. The cases are with the anodizer who is currently short staffed and backed up. Once the cases are anodized and shipped to me, I will be packing and shipping the kits asap. If you have any questions about your order or need to update your shipping addres, please send an email to evan@thevankeyboards.com'}
  //   ]
  // },
  {
    type: 'text',
    value: 'V4N4G0N R4 features the classic case design from R1/R2 with many quality of life improvements. The plate is now tadpole mount. There are alignment pins on the case making assembly a breeze. The case screws have been reduced to just 4. An S1 daughterboard connects the pcb to the computer. The new PCB supports both MX and Hall Effect switches and ships with Vial firmware.'
  },
  {
    type: 'html',
    value: `<style>.pp-MFQKW4BCJHVJA{text-align:center;border:none;border-radius:0.25rem;min-width:11.625rem;padding:0 2rem;height:2.625rem;font-weight:bold;background-color:#FFD140;color:#000000;font-family:"Helvetica Neue",Arial,sans-serif;font-size:1rem;line-height:1.25rem;cursor:pointer;}</style>
<form action="https://www.paypal.com/ncp/payment/MFQKW4BCJHVJA" method="post" target="_top" style="display:inline-grid;justify-items:center;align-content:start;gap:0.5rem;">
  <input class="pp-MFQKW4BCJHVJA" type="submit" value="Buy Now" />
  <img src=https://www.paypalobjects.com/images/Debit_Credit_APM.svg alt="cards" />
  <section> Powered by <img src="https://www.paypalobjects.com/paypal-ui/logos/svg/paypal-wordmark-color.svg" alt="paypal" style="height:0.875rem;vertical-align:middle;"/></section>
</form>`,
    center: true,
  },
  {
    type: 'image',
    value: charcoal_serika,
    md: 5,
    mdOffset: 2
  },
  {
    type: 'image',
    value: sand_muted_logo,
    md: 3,
  },
  {
    type: 'text',
    value: 'These kits are expected to take about 5 months to produce. Each kit contains the following items.'
  },
  {
    type: 'list',
    values: [
      'Charcoal or Sand anodized aluminum case',
      'Sealed Brass Mid Layer',
      '(4) Silver M3x10 screws',
      '(4) Rubber Feet',
      '(6) Tadpoles',
      'Full Space Layout FR4 Plate',
      'V4N4G0N Hybrid Hotswap PCB',
      'S1 Daughterboard, 2 screws and cable',
    ]
  },
  {
    type: 'image',
    value: charcoal_serika_side,
    lg: 2,
    md: 4
  },
  {
    type: 'image',
    value: charcoal_bottom,
    lg: 4,
    md: 8
  },
  {
    type: 'image',
    value: sand_pwork,
    md: 8,
    lg: 4
  },
  {
    type: 'image',
    value: sand_pwork_side,
    lg: 2,
    md: 4
  },
  {
    type: 'image',
    value: sand_muted_angle,
    lg: 6,
    md: 6
  },
  {
    type: 'image',
    value: sand_muted_back,
    lg: 6,
    md: 6
  },
]

export default VanagonR4Page;

