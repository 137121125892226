import { TZDate } from '@date-fns/tz';
import { isBefore, startOfDay } from 'date-fns';
import { useParams } from "react-router-dom"
import data from './data'
import { ItemType } from './types'
import Image from 'react-bootstrap/Image'
import './style.css'
import Updates from '../../Components/Updates'
import { useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function InStock() {
  let params = useParams()
  const now = new TZDate(startOfDay(new Date()), 'America/Chicago');

  useEffect(() => window.scrollTo(0, 0), [])

  const classes = (item: ItemType) => {
    const c = [];
    c.push(`${item.type}-element`);
    if (item.center) c.push('center');

    return c.join(' ');
  }

  const renderItem = (item: ItemType, index: number) => {
    if (item.date && isBefore(now, item.date)) return null;

    switch (item.type) {
      case 'header':
        return <h3 key={index} className={classes(item)}>{item.value}</h3>
      case 'sub-header':
        return <h4 key={index} className={classes(item)}>{item.value}</h4>
      case 'image':
        return <a key={index} rel="noopener" href={item.value}><Image className={classes(item)} src={item.value} fluid /></a>
      case 'html':
        return <div key={index} className={classes(item)} dangerouslySetInnerHTML={{ __html: item.value }} />
      case 'text':
        return <p key={index} className={classes(item)}>{item.value}</p>
      case 'list':
        return <ul key={index} className={classes(item)}>{item.values.map((listItem, index) => <li key={index}>{listItem}</li>)}</ul>
      case 'updates':
        return <Updates key={index} updates={item.updates} />
      case 'link':
        return <div key={index} className={classes(item)}><a href={item.value}>{item.description}</a></div>
      case 'quote':
        return <div key={index} className={classes(item)}><p>{item.value}</p><span>{item.author}</span></div>
      default:
        return <span key={index}>error rendering section</span>
    }
  }

  return (
    <Row className="DynamicPage">
      {data[params?.pageId || 0].map((item: ItemType, index: number) => (
        <Col sm={{span: item.sm || 12, offset: item.smOffset}}
          md={{span: item.md, offset: item.mdOffset}}
          lg={{span: item.lg, offset: item.lgOffset}}
          xl={{span: item.xl, offset: item.xlOffset}}
          xxl={{span: item.xxl, offset: item.xxlOffset}}
          key={index}
          className={item.center ? 'center' : ''}>
          {renderItem(item, index)}
        </Col>
      ))}
    </Row>
  );
}

export default InStock
